import React from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';

import device from '../device';

import Layout from '../components/layout';
import SEO from '../components/seo';

import {
    Gallary1,
    Gallary2,
    Gallary3,
    Gallary6,
    Gallary7,
    Gallary8,
    Gallary9,
    Gallary11,
    Gallary12,
    Gallary15,
    Gallary17,
    Gallary18,
    Gallary20,
    Gallary25,
    Gallary29,
    Gallary31,
    Gallary32,
    Gallary34,
    Gallary35,
    Gallary39,
    Gallary43,
} from '../components/gallary';

const ImageGalleryContainer = styled.div`
    margin-bottom: 1.5rem;

    @media ${device.laptop} {
        width: 60%;
        margin: 0 auto;
    }
`;

const GallaryPage = props => (
    <Layout {...props}>
        <SEO
            title="תמונות | פילאטיס בראשון לציון | עיצוב בראשון לציון | זומבה בראשון לציון"
            keywords={[
                `פילאטיס בראשון לציון`,
                `זומבה בראשון לציון`,
                `עיצוב וחיטוב בראשון לציון`,
                `סטודיו לנשים בראשון לציון`,
            ]}
        />
        <ImageGalleryContainer>
            <ImageGallery items={images} isRTL slideInterval={2000} showFullscreenButton={false} />
        </ImageGalleryContainer>
    </Layout>
);

export default GallaryPage;

const images = [
    { renderItem: () => <Gallary1 />, renderThumbInner: () => <Gallary1 /> },
    { renderItem: () => <Gallary2 />, renderThumbInner: () => <Gallary2 /> },
    { renderItem: () => <Gallary3 />, renderThumbInner: () => <Gallary3 /> },
    { renderItem: () => <Gallary6 />, renderThumbInner: () => <Gallary6 /> },
    { renderItem: () => <Gallary7 />, renderThumbInner: () => <Gallary7 /> },
    { renderItem: () => <Gallary8 />, renderThumbInner: () => <Gallary8 /> },
    { renderItem: () => <Gallary9 />, renderThumbInner: () => <Gallary9 /> },
    { renderItem: () => <Gallary11 />, renderThumbInner: () => <Gallary11 /> },
    { renderItem: () => <Gallary12 />, renderThumbInner: () => <Gallary12 /> },
    { renderItem: () => <Gallary15 />, renderThumbInner: () => <Gallary15 /> },
    { renderItem: () => <Gallary17 />, renderThumbInner: () => <Gallary17 /> },
    { renderItem: () => <Gallary18 />, renderThumbInner: () => <Gallary18 /> },
    { renderItem: () => <Gallary20 />, renderThumbInner: () => <Gallary20 /> },
    { renderItem: () => <Gallary25 />, renderThumbInner: () => <Gallary25 /> },
    { renderItem: () => <Gallary29 />, renderThumbInner: () => <Gallary29 /> },
    { renderItem: () => <Gallary31 />, renderThumbInner: () => <Gallary31 /> },
    { renderItem: () => <Gallary32 />, renderThumbInner: () => <Gallary32 /> },
    { renderItem: () => <Gallary34 />, renderThumbInner: () => <Gallary34 /> },
    { renderItem: () => <Gallary35 />, renderThumbInner: () => <Gallary35 /> },
    { renderItem: () => <Gallary39 />, renderThumbInner: () => <Gallary39 /> },
    { renderItem: () => <Gallary43 />, renderThumbInner: () => <Gallary43 /> },
];
